import * as React from 'react'
import { useState, useEffect } from 'react';
import { Nav } from '@inc/index'
import Tel from "@svgs/tel.svg"
import Nippo from "@svgs/nippo.svg"
import HasekoArvest from "@svgs/haseko_arvest_footer.svg"
import HasekoCorporation from "@svgs/haseko_corporation_footer.svg"

const Footer = () => {

  const [footerBtm, setFooterBtm] = useState(0)

  useEffect(() => {
    let floatNav = document.getElementById('floatNav')
    // console.log(floatNav);
    let floatNavH = floatNav.clientHeight;
    setFooterBtm(floatNavH)
  }, []);

  const style = {
    paddingBottom: footerBtm
  }

  return (
    <>
      <footer
        style={style}
      >

        <Nav />

        <div className="footer">

          {/* <div className="ad-box">
            <div className="flex-box">
              <p className='p1'>予告広告</p>
              <p className='p2'>※本広告を行うまでは、契約又は申し込みに一切応じられません。また申込みの順位の確保に関する措置は講じられません。（販売予定時期／2022年6月下旬）</p>
            </div>
          </div> */}

          <div className="tel-box">
            <a href="tel:0120-639-065" className='tel'>
              <p className="p1">お電話でのお問い合わせは<br className='_sp' />「ル・サンク小田原栄町」マンションギャラリー</p>
              <Tel
                alt="「ル・サンク小田原栄町」マンションギャラリー"
              />
              <p className="p2">営業時間／10:00〜18:00　定休日／水・木曜日 ※祝日除く</p>
            </a>
          </div>

          <div className="cntact-box">

            <a href="https://www.nippo-c.co.jp/" target="_blank" rel="noopener noreferrer" aria-label="NIPPO" className="nippo">
              <Nippo
                alt="NIPPO"
              />
            </a>

            <a href="https://www.haseko-urbest.com/" target="_blank" rel="noopener noreferrer" aria-label="長谷工アーベスト" className="haseko">
              <HasekoArvest
                alt="長谷工アーベスト"
              />
            </a>

            <a href="https://www.haseko.co.jp/hc/" target="_blank" rel="noopener noreferrer" aria-label="長谷工コーポレーション" className="haseko-c">
              <HasekoCorporation
                alt="長谷工コーポレーション"
              />
            </a>

          </div>

        </div>

        <div className="cr-box">
          <small>Copyright © NIPPO Corporation. All Rights Reserved.</small>
        </div>

      </footer>
    </>
  )
}
export default Footer