import * as React from 'react'
import { useEffect } from 'react';
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
// import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Logo from "@svgs/logo.svg"
import LogoLoad from '@svgs/logo-load.svg'

const Header = () => {

  const location = useLocation().pathname;
  let boolean;
  if (location === "/") {
    boolean = true
  } else {
    boolean = false
  }

  useEffect(() => {
    if (location === "/") {
      let loadWrap = document.getElementById('loadWrap');
      setTimeout(() => {
        loadWrap.classList.add('no_act');
      }, 1000)
    }
  }, [location]);

  return (
    <>
      <div className={`com-loadwrap ${boolean ? 'true' : 'false'}`} id="loadWrap">
        <LogoLoad />
      </div>

      <header>
        <div className="header">

          <Link
            to="/"
            aria-label="logo"
            className='logo'
          >
            <Logo
              alt="ル・サンク小田原栄町"
            />
          </Link>

          <div className="link-box">
            <Link
              to="/map/"
              className='link-map'
            >
              <span>現地案内図</span>
            </Link>
            <a href="https://www.haseko-sumai.com/asp/syuto/sinki/n-odawara/gaiyou.asp" target="_blank" rel="noopener noreferrer" className='link-out'>
              <span>物件概要</span>
            </a>
            <a href="https://www.haseko-sumai.com/asp/syuto/sinki/n-odawara/request.asp" target="_blank" rel="noopener noreferrer" className='link-req'>
              <span>資料請求</span>
            </a>
            <a href="https://airrsv.net/AKR0034741846/calendar" target="_blank" rel="noopener noreferrer" className='link-res'>
              <span>来場予約</span>
            </a>
          </div>

        </div>
      </header>
    </>
  )
}
export default Header